//export const REACT_APP_API_URL="http://b2webhookapi.bamboobox.in/webhooks/";
//export const REACT_APP_LOGIN_URL="http://login.bamboobox.in";
//export const REACT_APP_AUTH_URL="http://loginapi.bamboobox.in/authorize/cookie";

export const REACT_APP_API_URL=window._env_.REACT_APP_API_URL;
export const REACT_APP_LOGIN_URL=window._env_.REACT_APP_LOGIN_URL;
export const REACT_APP_AUTH_URL=window._env_.REACT_APP_AUTH_URL;



