import HookList from './HookList';
import HookHeader from './HookHeader';
import { useEffect, useState } from 'react';
import NewHookForm from './NewHookForm';
import { useReducer } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useClearCache } from 'react-clear-cache';
import "./App.css";
import theme from './Theme';
import CheckIcon from '@mui/icons-material/Check';
import { ThemeProvider } from '@material-ui/styles';
import { useAuthState } from "@bamboobox/b2logincheck";
import { REACT_APP_API_URL } from "./constants";
import { Dialog, DialogActions, DialogContentText, DialogTitle, Divider, FormControl, FormHelperText, Input, InputLabel, makeStyles, MenuItem, Select, TextareaAutosize, TextField, Paper } from '@material-ui/core';
import { Autocomplete, Button, DialogContent, Typography } from '@mui/material';
import { Check, CheckCircle } from '@mui/icons-material';



function App() {

  const API_URL = REACT_APP_API_URL;

  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  
  useEffect(() => {
    console.log("latest version",isLatestVersion);
    if(!isLatestVersion) {
        // e.preventDefault();
        emptyCacheStorage();
        window.location.reload(true);
          
    }
}, [isLatestVersion])

  const [inCreate, setInCreate] = useState(false);
  const [reRender, setReRender] = useState(true);
  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: "",
      description: "",
      type: "GET",
      actiontype: "event",
      action: ""

    }
  );
  var selectedIDs = [];
  var userData = useAuthState();
  async function handleSubmit(event) {
    event.preventDefault();
    console.log('Name:', formInput.name, 'Descrption: ', formInput.description, 'type:', formInput.type, 'action', formInput.action);
    // You should see email and password in console.
    // ..code to submit form to backend here...
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'tenantid': userData.user.tenantId },
      body: JSON.stringify(formInput),
      credentials: "include"
    };

    await fetch(API_URL, requestOptions).then(setTimeout(onGetOutOfCreate(), 3000)).catch((error) => { console.log(error); setTimeout(onGetOutOfCreate(), 3000) });
    setReRender(!reRender);
  }
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(async () => {
    const requestOptions = {
      headers: {
        'tenantid': userData.user.tenantId,
      }, credentials: "include"
    }
    setLoading(true);
    const res = await fetch(API_URL, requestOptions);
    let resJson = await res.json();
    setData(resJson.data);
    setLoading(false);
  }, [reRender, userData.user.tenantId, API_URL])
  async function deleteWebHook(id) {
    await fetch(API_URL + id, {
      method: 'DELETE', headers: {
        'tenantid': userData.user.tenantId,
      }, credentials: "include"
    });
  }
  function onCreateNewWebHook() {
    setInCreate(true);
  }
  function handleClose() {
    setInCreate(false);
  }
  const hookType = [{ label: 'event' }]
  const hookMethod = [{ label: "GET" }, { label: "POST" }, { label: "PUT" }, { label: "DELETE" }]

  function onDeleteSelected() {
    var promises = [];
    for (let id of selectedIDs) promises.push(deleteWebHook(id));
    Promise.all(promises).then(res => { console.log('success', res); setReRender(!reRender) })
      .catch(err => console.log('error', err));

  }
  function setSelectIDs(...s) {
    selectedIDs = s;
  }
  function onGetOutOfCreate() {
    setInCreate(false);
  }
  return <ThemeProvider theme={theme}>
    <HookHeader onCreateNewWebHook={onCreateNewWebHook} onDeleteSelected={onDeleteSelected} />
    <div ><Paper style={{
      background: "white",

      borderRadius: "16px"
    }}>

      <Dialog
        maxWidth='lg'
        open={inCreate} onClose={handleClose}>
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            width: "562px",
            paddingBottom: "0px!important",
            paddingRight: "34px"
          }}
        >
          <DialogTitle><Typography variant="Ag" style={{ color: "#595959" }}>Create New Webhook</Typography> </DialogTitle>

          <span style={{ marginTop: "20px", paddingRight: "20px" }}>

            <CloseOutlinedIcon

              style={{
                cursor: "pointer", position: "absolute",
                border: "1px",

                borderRadius: "88px",
                width: "20px",
                height: "20px",
                color: "white",
                background: "#A0A0A0"
              }}
              onClick={handleClose}
            />
          </span>
        </div>


        <DialogContent>


          {/* <TextField variant='outfield'></TextField> */}
          {/* <InputLabel htmlFor="name" id="label-name">Name</InputLabel> */}
          <div style={{
            marginRight: "14px",
            display: "flex",
            justifyContent: "space-between"
            // width: "562px",
          }}>
            <TextField style={{ width: "254px" }} size="small" id="outlined-basic" label="Name" variant="outlined" onInput={e => setFormInput({ name: e.target.value })} />


            <TextField style={{ width: "254px" }} size="small" id="outlined-basic" label="Event Name" variant="outlined" onInput={e => setFormInput({ action: e.target.value })} />
          </div>

          <div style={{ marginTop: "20px" }}>

            <TextField style={{ width: "540px" }} size="large" id="description" label="Description" variant="outlined" onChange={e =>
              setFormInput({ description: e.target.value })
            } />

          </div>

          <Divider style={{ marginTop: "24px", marginBottom: "24px" }} />
          <div style={{
            marginRight: "10px",
            display: "flex",
            justifyContent: "space-between"

          }}>
            <Autocomplete
              id="combo-box-demo"
              options={hookMethod}
              sx={{ width: 260 }}
              renderInput={(params) => <TextField style={{ width: "245px" }} {...params} size="small" variant="outlined" label="Hook Method" onChange={e => setFormInput({ type: e.target.value })} />}
            />


            <Autocomplete
              id="combo-box-demo"
              options={hookType}
              sx={{ width: 260 }}
              renderInput={(params) => <TextField style={{ width: "245px" }} {...params} size="small" variant="outlined" label="Hook Type" onChange={e => setFormInput({ actiontype: e.target.value })} />}
            />
          </div>

        </DialogContent>
        <DialogActions style={{ paddingbottom: "400px" }}>
          <Button style={{ borderRadius: "18px", color: "#A0A0A0", fontWeight: 800, fontFamily:'Nunito Sans'
, fontStyle: "Extrabold", fontSize: "12px", height: "32px", Boxhadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)" }} onClick={handleClose} variant="outlined" startIcon={<CloseOutlinedIcon />}>
            <Typography variant="Ag" >
              Delete
            </Typography>
          </Button>
          <Button color="secondary" style={{ background: "#21DA8C", borderRadius: "18px", color: "#FFFFFF", fontWeight: 700,fontFamily:'Nunito Sans', fontStyle: "bold", fontSize: "12px", height: "32px", marginLeft: "11px" }} onClick={e => handleSubmit(e)} variant="contained" startIcon={<CheckIcon />}>
            <Typography variant="Ag" >
              proceed to Setup Filter
            </Typography>

          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
    </div>
    <div style={{ height: '100px',margin:'0 3% 0 1%', minHeight: '500px' }} id="loading-div">
      <HookList setSelectIDs={setSelectIDs} data={data} loading={loading} />

    </div>

  </ThemeProvider>
}


export default App;