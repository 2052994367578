import React, { useEffect, useState } from "react";
import { DataGrid } from '@material-ui/data-grid';
import { useAuthState } from "@bamboobox/b2logincheck";
import { REACT_APP_API_URL } from "./constants";
import { styled } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { Grid, TablePagination } from "@material-ui/core";


function HookList(props) {

  const API_URL = REACT_APP_API_URL;
  const [pageSize, setPageSize] = React.useState(25);

  function onSelectionModelChange(obj) {
    props.setSelectIDs(...obj.selectionModel);
  }
  function customCheckbox(theme) {
    return {
      "& .MuiCheckbox-root svg": {
        width: 16,
        height: 16,
        backgroundColor: "transparent",
        border: `1px solid ${theme.palette.mode === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
          }`,
        borderRadius: 2
      },
      "& .MuiCheckbox-root svg path": {
        display: "none"
      },
      "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
        backgroundColor: "#1890ff",
        borderColor: "#1890ff"
      },
      "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
        position: "absolute",
        display: "table",
        border: "2px solid #fff",
        borderTop: 0,
        borderLeft: 0,
        transform: "rotate(45deg) translate(-50%,-50%)",
        opacity: 1,
        transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
        content: '""',
        top: "50%",
        left: "39%",
        width: 5.71428571,
        height: 9.14285714
      },
      "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after": {
        width: 8,
        height: 8,

        transform: "none",
        top: "39%",
        border: 0
      }
    };
  }
  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    borderRadius: "18px",
    paddingTop: "15px",
    boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
    paddingBottom: "13px",
    fontFamily: [

    ].join(","),
    WebkitFontSmoothing: "auto",
    "& .MuiDataGrid-colCellTitle": {
      fontSize: "14px"
    },
    "& .MuiDataGrid-cell MuiDataGrid-cellLeft": {
      fontSize: "10px!important",

    },
    // letterSpacing: "normal",
    // "& .MuiDataGrid-columnsContainer": {
    //   height: "66px"
    //   // backgroundColor: theme.palette.mode === "light" ? "#ffffff" : "#1d1d1d"
    // },
    // "& .MuiDataGrid-iconSeparator": {
    //   display: "none"
    // },
    // "& .MuiPaginationItem-root": {
    //   borderRadius: 0
    // },
    "& svg.MuiDataGrid-root.MuiDataGrid-iconSeparator": {
      color: "#ffffff!important",
      opacity: 0
    },
    "& .MuiIconButton-root.Mui-disabled": {
      color: "#37CAD3"
    },
    ...customCheckbox(theme)
  }));

  const columns = [
    { field: 'id', headerName: 'ID', width: 250 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'actiontype', headerName: 'Action Type', width: 150 },
    { field: 'action', headerName: 'Action', width: 150 },
    { field: 'type', headerName: 'Verb', width: 150 },
    { field: 'url', headerName: 'URL', width: 400 },

  ];
  const [data, setData] = useState(props.data);
  const userData = useAuthState();
  function CustomFooterStatusComponent(props) {
    { console.log("props:", props) }
    return (
      <>
        total Rows
      </>
    );
  }
  const [loading, setLoading] = useState(true);
  useEffect(() => {

    setData(props.data)
    setLoading(props.loading)
  }, [props.data, props.loading]);


  return <StyledDataGrid rows={data} columns={columns} loading={loading}
    pageSize={pageSize}
    onPageSizeChange={(newPage) => setPageSize(newPage)}
    checkboxSelection={true} onSelectionModelChange={onSelectionModelChange}
  // components={{
  //   Footer: CustomFooterStatusComponent
  // }

  // }
  />
}
export default HookList;