import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

function HookHeader(props) {

  return <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "29px" ,margin:'1.5% 3% 0 1%'}}>
    <span style={{ padding: "4px, 10px, 4px, 10px" }}>
      <Typography 
      
     style={{ 
      fontFamily:"Nunito Sans", 
      fonStyle: 'normal',
      fontWeight: '700',
      fontSize: '20px',
      lineHeight: '24px',      
      color: '#595959'
    }}      
      variant="Ag" align="left">
        Web Hooks
      </Typography>
    </span>
    <span style={{ padding: "4px, 10px, 4px, 10px" }}>
      <Button style={{ borderRadius: "18px", color: "#21DA8C", fontWeight: 800,fontFamily:"Nunito Sans", fontStyle: "normal", fontSize: "12px", height: "32px", Boxhadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)" }} onClick={props.onDeleteSelected} variant="outlined" startIcon={<DeleteIcon />}>
        <Typography variant="Ag" >
          Delete
        </Typography>
      </Button>
      <Button color="secondary" style={{ background: "#21DA8C", borderRadius: "18px", color: "#FFFFFF", fontWeight: 700,fontFamily:"Nunito Sans", fontStyle: "normal", fontSize: "14px", height: "32px", marginLeft: "30px" }} onClick={props.onCreateNewWebHook} variant="contained" startIcon={<AddIcon />}>
        <Typography variant="Ag" >
          New Webhook
        </Typography>
      </Button>
    </span>
  </div>
}
export default HookHeader;
