import React from "react";
import Typography from "@material-ui/core/Typography";
import { Input, InputLabel, FormHelperText, Select, MenuItem, TextareaAutosize, Button } from "@material-ui/core";
import { FormControl } from '@material-ui/core';
import { useReducer } from "react";
import theme from './Theme';
import { ThemeProvider } from '@material-ui/styles';
import { useAuthState } from "@bamboobox/b2logincheck";
import { REACT_APP_API_URL } from "./constants";

function NewHookForm(props) {
  const API_URL = REACT_APP_API_URL;
  const userData = useAuthState();
  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: "",
      description: "",
      type: "GET",
      actiontype: "event",
      action: ""

    }
  );

  async function handleSubmit() {
    // event.preventDefault();
    console.log('Name:', formInput.name, 'Descrption: ', formInput.description, 'type:', formInput.type, 'action', formInput.action);
    // You should see email and password in console.
    // ..code to submit form to backend here...
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'tenantid': userData.user.tenantId },
      body: JSON.stringify(formInput),
      credentials: "include"
    };

    await fetch(API_URL, requestOptions).then(setTimeout(props.onGetOut(), 3000)).catch((error) => { console.log(error); setTimeout(props.onGetOut(), 3000) });

  }
  return <ThemeProvider theme={theme}><div>
    <Typography variant="h5" align="center" component="h2" gutterBottom>
      Create New Webhook
    </Typography>
    <Button color="secondary" onClick={a => { props.onGetOut() }}>Cancel</Button>
    <form onSubmit={handleSubmit} method="post" action={API_URL}>
      <br></br>
      <FormControl>
        <InputLabel htmlFor="name" id="label-name">Name</InputLabel>
        <Input id="name" aria-describedby="my-helper-text" onInput={e => setFormInput({ name: e.target.value })} />
        <FormHelperText id="my-helper-text">Give it a short name.</FormHelperText>
      </FormControl>
      <br></br>
      <FormControl>
        <InputLabel htmlFor="description" id="label-description">Description</InputLabel>
        <br></br><br></br><br></br>
        <TextareaAutosize id="description" aria-describedby="my-helper-text-describe" onInput={e => setFormInput({ description: e.target.value })} />
        <FormHelperText id="my-helper-text-describe">You could jot down your notes in description.</FormHelperText>
      </FormControl>
      <br></br><br></br>
      <FormControl>
        <InputLabel id="label-type" htmlFor="type" >Hook Method</InputLabel>

        <Select id="type" value={formInput.type} onChange={e => setFormInput({ type: e.target.value })} >
          <MenuItem value={"GET"}>GET</MenuItem>
          <MenuItem value={"POST"}>POST</MenuItem>
          <MenuItem value={"PUT"}>PUT</MenuItem>
          <MenuItem value={"DELETE"}>DELETE</MenuItem>
        </Select>
        <FormHelperText id="my-helper-text-type">This is the HTTP verb your hook would listen for.</FormHelperText>
      </FormControl>
      <br></br><br></br>
      <FormControl>
        <InputLabel id="label-actiontype" htmlFor="actiontype">Hook Type</InputLabel>
        <Select labelId="label-type" id="actiontype" value="event" onChange={e => setFormInput({ actiontype: e.target.value })}>
          <MenuItem value="event">Event</MenuItem>
        </Select>
        <FormHelperText id="my-helper-text-actiontype">Right now only Event type is supported.</FormHelperText>
      </FormControl>
      <br></br><br></br>
      <FormControl>
        <InputLabel htmlFor="action" id="label-action">Event Name</InputLabel>
        <Input id="action" aria-describedby="my-helper-text-action" onInput={e => setFormInput({ action: e.target.value })} />
        <FormHelperText id="my-helper-text-action">Provide your event Name</FormHelperText>

      </FormControl>
      <br></br><br></br>
      <Button color="primary" type="submit">Submit</Button>
    </form>
  </div>
  </ThemeProvider>
};

export default NewHookForm;