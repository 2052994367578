import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from "@bamboobox/b2logincheck";
import {ResourceCheck} from '@bamboobox/b2resource-check/dist'
import {REACT_APP_LOGIN_URL,REACT_APP_AUTH_URL} from "./constants";
import { version } from '../package.json';
import CacheBuster from 'react-cache-buster';

console.log("verison of build", version);

const resources=["webhooks_setup"]

ReactDOM.render(
  <AuthProvider loginURL={REACT_APP_LOGIN_URL} authcheckURL={REACT_APP_AUTH_URL}>
  <React.StrictMode>
  <CacheBuster
        currentVersion={version}
        isEnabled={true} //If false, the library is disabled.
        isVerboseMode={true}>
          <ResourceCheck component={<App />} resources={resources} />
      {/* <App /> */}
  </CacheBuster>
  </React.StrictMode>
  </AuthProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
